<template>
  <div class="DepositCertificate document-content">
    <div class="docHeader">
      <h2>存证中心</h2>
      <div class="updateTime">发布时间：2022-01-21 9:00</div>
    </div>
    <Deposit class="markdown-body"></Deposit>
  </div>
</template>

<script setup>
import Deposit from "./mdDocument/deposit.md";
export default {
  data() {
    return {};
  },
  components: {
    Deposit,
  },
};
</script>

<style lang="less" scoped>
.DepositCertificate {
}
</style>